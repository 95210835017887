import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Container } from 'react-bootstrap';
import axios from 'axios';
import { MdOutlineEmail } from 'react-icons/md';
import { LuPhoneCall } from 'react-icons/lu';

const Contacts = ({ data }) => {
  const [contactsData, setContactsData] = useState({});
  const [loading, setLoading] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}accounts/${data.id}/contacts`,
        {
          headers: {
            Authorization: `Bearer ${t}`
          }
        }
      );
      setContactsData(response.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <>Loading...</>;
  }
  console.log(contactsData);
  return (
    <>
      {contactsData.map((contact, index) => (
        <Container
          key={contact.id || index}
          className="bg-white mt-1 pt-3 shadow-sm rounded"
        >
          <Row>
            <Col sm={6} className="d-flex flex-column">
              <p className="text-dark mb-0 text-uppercase text-base fw-bolder">
                {contact.first_name} {contact.last_name}
              </p>
              <p className="mb-2">{contact.role}</p>
            </Col>
            <Col sm={6} className="d-flex flex-column align-items-end">
              <p className="mb-0">
                <MdOutlineEmail />{' '}
                <a
                  href={`mailto:${contact.email}`}
                  className="text-decoration-none"
                >
                  {contact.email}
                </a>
              </p>
              <p className="mb-2">
                <p className="mb-2">
                  {contact.phone && (
                    <a
                      href={`tel:${contact.phone}`}
                      className="text-decoration-none"
                    >
                      <LuPhoneCall /> {contact.phone}
                    </a>
                  )}
                </p>
              </p>
            </Col>
          </Row>
        </Container>
      ))}
    </>
  );
};

Contacts.propTypes = {
  data: PropTypes.object.isRequired
};

export default Contacts;
