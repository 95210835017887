import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Container } from 'react-bootstrap';
import SubtleBadge from 'components/common/SubtleBadge';

const Variants = ({ data }) => {
  const { variants } = data;
  const [formattedOptions, setFormattedOptions] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [filteredVariants, setFilteredVariants] = useState(variants);

  useEffect(() => {
    const groupedOptions = {};

    variants.forEach(variant => {
      Object.entries(variant.options).forEach(([key, value]) => {
        if (!groupedOptions[key]) {
          groupedOptions[key] = [];
        }
        if (!groupedOptions[key].includes(value)) {
          groupedOptions[key].push(value);
        }
      });
    });

    setFormattedOptions(groupedOptions);
  }, [variants]);

  const handleOptionClick = (key, value) => {
    setSelectedOptions(prev => {
      const newSelectedOptions = { ...prev };
      if (newSelectedOptions[key] === value) {
        delete newSelectedOptions[key];
      } else {
        newSelectedOptions[key] = value;
      }
      return newSelectedOptions;
    });
  };

  useEffect(() => {
    const filtered = variants.filter(variant => {
      return Object.entries(selectedOptions).every(([key, value]) => {
        return variant.options[key] === value;
      });
    });
    setFilteredVariants(filtered);
  }, [selectedOptions, variants]);

  return (
    <>
      <Container className="bg-white mt-1 pt-3 shadow-sm rounded container">
        <Row>
          <Col>
            <Container>
              {Object.entries(formattedOptions).map(([key, values]) => (
                <Row key={key} className="d-flex align-items-center fs-10 mb-1">
                  <Col md={2}>
                    <strong className="text-capitalize">
                      {key} ({values?.length})
                    </strong>
                    :{' '}
                  </Col>
                  <Col
                    md={10}
                    className="text-capitalize ps-1 d-flex flex-wrap gap-1"
                  >
                    {values.map(value => {
                      const isSelected = selectedOptions[key] === value;
                      return (
                        <button
                          key={value}
                          className={`border border-white px-2 py-1 text-capitalize text-center ${
                            isSelected ? 'bg-primary text-white' : ''
                          }`}
                          onClick={() => handleOptionClick(key, value)}
                          style={{
                            width: '45px',
                            flex: key === 'ring size' ? 'none' : '1 1 auto',
                            textDecoration: 'none',
                            verticalAlign: 'middle'
                          }}
                        >
                          {value}
                        </button>
                      );
                    })}
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
      {filteredVariants.length === 0 ? (
        <Container className="bg-white mt-1 shadow-sm rounded p-3">
          <p className="text-muted">
            No variants available. Try adjusting your filters.
          </p>
        </Container>
      ) : (
        filteredVariants.map((item, index) => (
          <Container
            key={item.id || index}
            className="bg-white mt-1 pt-3 shadow-sm rounded"
          >
            <Row>
              <Col sm={6} md={2} className="d-flex align-items-center">
                <img
                  src={item?.media[0].url}
                  alt={item?.name}
                  className="img-fluid"
                />
              </Col>
              <Col
                sm={6}
                md={6}
                className="d-flex flex-column justify-content-center"
              >
                <p className="text-dark mb-0 text-uppercase text-base fw-bolder">
                  {item.sku}
                </p>
                <p className="mb-2 fs-10">{item?.name || data?.name}</p>
              </Col>
              <Col sm={6} md={2} className="d-flex align-items-center">
                <p className="text-dark mb-0 fs-9 ">
                  {Object.entries(item?.options).map(([key, value]) => (
                    <span key={key} className="d-block">
                      <strong className="text-capitalize">{key}</strong>:{' '}
                      <span className="text-capitalize">{value}</span>
                    </span>
                  ))}
                </p>
              </Col>
              <Col sm={6} md={2} className="d-flex align-items-center">
                {item.track_quantity ? (
                  <SubtleBadge
                    pill
                    bg="success"
                    className="fs-10 ml-1 fw-normal"
                  >
                    STATUS
                  </SubtleBadge>
                ) : (
                  <SubtleBadge
                    pill
                    bg="danger"
                    className="fs-10 ml-1 fw-normal"
                  >
                    item unavailable
                  </SubtleBadge>
                )}
              </Col>
            </Row>
          </Container>
        ))
      )}
    </>
  );
};

Variants.propTypes = {
  data: PropTypes.object.isRequired
};

export default Variants;
