import React, { useState, useEffect, Suspense } from 'react';
import AccountDetailsHeader from './ProductDetailsHeader';
import AccountInfo from './ProductInfo';
import AccountSideBar from './ProductSideBar';
// import AccountLog from './AccountLog';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_ENDPOINT}products`;
import { Container, Row, Col } from 'react-bootstrap';
import LoadingSpinner from 'components/common/LoadingSpinner';

const productDetails = () => {
  const [data, setData] = useState({});
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  if (!productId) {
    return <>No Products ID!!!</>;
  }
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/${productId}`, {
        headers: {
          Authorization: `Bearer ${t}`
        }
      });
      setData(response.data?.data);
      setIsLoading(false);
      console.log('Data is loaded!');
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <AccountDetailsHeader data={data} />
      <Container fluid>
        <Row>
          <Col md={8} style={{ paddingLeft: 0 }}>
            <AccountInfo data={data} />
          </Col>
          <Col md={4} style={{ paddingRight: 0 }}>
            <AccountSideBar data={data} />
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
};

export default productDetails;
