import paths from './paths';

export const accountManagement = {
  label: 'Account Management',
  children: [
    {
      name: 'Accounts',
      icon: 'users',
      to: paths.accountsManagment,
      active: true
    },
    {
      name: 'Contacts',
      icon: 'user',
      to: paths.contactsManagement,
      active: true
    }
  ]
};

export const inventoryManagement = {
  label: 'Inventory',
  children: [
    {
      name: 'Products',
      icon: 'tags',
      to: paths.inventoryProducts,
      active: true
    },
    {
      name: 'Collections',
      icon: 'th',
      to: paths.inventoryCollections,
      active: true
    },
    {
      name: 'Categories',
      icon: 'list',
      to: paths.inventoryCategories,
      active: true
    },
    {
      name: 'Price Groups',
      icon: 'dollar-sign',
      to: paths.inventoryPriceGroup,
      active: true
    },
    {
      name: 'Transfers',
      icon: 'exchange-alt',
      to: paths.inventoryTransfers,
      active: true
    },
    {
      name: 'Tags',
      icon: 'tag',
      to: paths.inventoryTag,
      active: true
    }
  ]
};

export const salesManagement = {
  label: 'Sales',
  children: [
    {
      name: 'Orders',
      icon: 'shopping-cart',
      to: paths.salesOrders,
      active: true
    },
    {
      name: 'Payments',
      icon: 'cart-plus',
      to: paths.salesPayments,
      active: true
    },
    {
      name: 'Invoices',
      icon: 'file-invoice-dollar',
      to: paths.salesInvoices,
      active: true
    }
  ]
};

export const productionManagement = {
  label: 'Production',
  children: [
    {
      name: 'Production Jobs',
      icon: 'layer-group',
      to: paths.productionJobs,
      active: true
    }
  ]
};

export const procurementManagement = {
  label: 'Procurement',
  children: [
    {
      name: 'Purchases',
      icon: 'money-bill-alt',
      to: paths.purchases,
      active: true
    }
  ]
};

export const siteMaps = [
  {
    name: 'Dashboard',
    icon: 'chart-pie',
    to: '/',
    exact: true
  },
  {
    name: 'Inventory',
    icon: 'box',
    children: [
      {
        name: 'Products',
        to: '/inventory/products'
      },
      {
        name: 'Categories',
        to: '/inventory/categories'
      }
    ]
  },
  {
    name: 'Sales',
    icon: 'shopping-cart',
    children: [
      {
        name: 'Orders',
        to: '/sales/orders'
      },
      {
        name: 'Invoices',
        to: '/sales/invoices'
      }
    ]
  }
];

export default [
  accountManagement,
  inventoryManagement,
  salesManagement,
  procurementManagement,
  productionManagement
  // dashboardRoutes,
  // appRoutes,
  // pagesRoutes,
  // modulesRoutes,
  // documentationRoutes
];
