import React from 'react';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ height = '120px', width = '120px' }) => {
  return (
    <div className="d-flex justify-content-center">
      <Lottie
        animationData={infiniteLoop}
        loop={true}
        style={{ height, width }}
      />
    </div>
  );
};

LoadingSpinner.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};

export default LoadingSpinner;
