import React, { useEffect } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LogoutContent from 'components/authentication/LogoutContent';
import bgImg from 'assets/img/generic/vossgi_background.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';

const Logout = () => {
  const { logout, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        if (!token) {
          console.error('No token found, cannot logout.');
          navigate('/');
          return;
        }
        localStorage.removeItem('token');
        // console.log('Logging out... with token', token);
        const response = await axios.post(
          '${process.env.REACT_APP_API_ENDPOINT}auth/logout',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        );
        console.log('Response:', response);
        logout();
      } catch (error) {
        console.error('Logout error:', error);
      }
    };

    logoutUser();
  }, [token, logout]);

  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <LogoutContent layout="split" titleTag="h3" />
      </div>
    </AuthSplitLayout>
  );
};

export default Logout;
