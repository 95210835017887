import React, { useState, useEffect, Suspense } from 'react';
import { Card, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport
} from '@mui/x-data-grid';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { formatNumberWithCommas } from 'helpers/utils';

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}price-groups`;

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
    valueGetter: params => params?.toUpperCase()
  },
  {
    field: 'code',
    headerName: 'Code',
    flex: 1
  },
  {
    field: 'markup',
    headerName: 'Markup',
    flex: 1,
    valueGetter: params => parseFloat(params).toFixed(2)
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    flex: 2,
    renderCell: params => {
      const date = new Date(params.value);
      const formattedDate = date.toISOString().split('T')[0];
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      const formattedTime = new Intl.DateTimeFormat('en-US', options).format(
        date
      );
      return (
        <span>
          {formattedDate} {formattedTime}
        </span>
      );
    }
  }
];

const PriceGroups = () => {
  const [data, setData] = useState([]); // All data
  const [displayedData, setDisplayedData] = useState([]); // Paginated data
  const [page, setPage] = useState(0); // Current page
  const [pageSize, setPageSize] = useState(100); // Records per page
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const CustomExportButton = () => {
    const handleExport = () => {
      const csv = Papa.unparse(data);
      const blob = new Blob([csv], { type: 'text/csv' });
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const fileName = `categories_data_${timestamp}.csv`;
      saveAs(blob, fileName);
    };

    return (
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="external-link-alt"
        transform="shrink-3"
        onClick={handleExport}
      >
        <span className="d-none d-sm-inline-block ms-1">Export All</span>
      </IconButton>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px'
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <CustomExportButton />
      </GridToolbarContainer>
    );
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}`, {
        headers: {
          Authorization: `Bearer ${t}`
        }
      });
      const transformedData = response.data?.data || [];
      setData(transformedData);
      setRowCount(transformedData.length);
      setDisplayedData(transformedData.slice(0, pageSize));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const start = page * pageSize;
    const end = start + pageSize;
    setDisplayedData(data.slice(start, end));
  }, [page, pageSize, data]);

  const handleSearchChange = event => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = data.filter(
      item =>
        item.name.toLowerCase().includes(query) ||
        String(item.product_count).includes(query)
    );
    setPage(0);
    setRowCount(filteredData.length);
    setDisplayedData(filteredData.slice(0, pageSize));
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Card className="mb-3">
        <Card.Header className="mb-1 pb-1 d-flex justify-content-between">
          Price Groups
          <span className="text-muted ms-auto">
            {formatNumberWithCommas(data.length)}{' '}
            <span className="small">records found</span>
          </span>
        </Card.Header>
        <Card.Body className="pt-1">
          <div className="mb-1 flex">
            <Form.Group>
              <Form.Control
                type="text"
                name="filter"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex', height: '75vh', width: '100%' }}>
            <DataGrid
              className="fs-10"
              rows={displayedData}
              columns={columns}
              pagination
              paginationMode="server"
              pageSizeOptions={[100]}
              pageSize={pageSize}
              rowCount={rowCount}
              loading={loading}
              onPaginationModelChange={({ page, pageSize }) => {
                setPage(page);
                setPageSize(pageSize);
              }}
              slots={{
                toolbar: CustomToolbar
              }}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#2c7be5',
                  fontWeight: 'bold'
                },
                '& .MuiDataGrid-footerContainer': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                },
                '& .MuiPagination-root': {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                },
                '& .MuiDataGrid-toolbarContainer': {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '2px',
                  color: 'black',
                  minHeight: '32px'
                },
                '& .MuiButtonBase-root': {
                  color: 'black',
                  minHeight: '24px'
                },
                '& .MuiSvgIcon-root': {
                  color: 'black',
                  fontSize: '1rem'
                },
                '& .MuiTablePagination-selectLabel': {
                  display: 'none', // Hides "Rows per page" label
                  paddingTop: '12px'
                },
                '& .MuiTablePagination-displayedRows': {
                  display: 'none', // Hides record count text
                  paddingTop: '12px'
                },
                '& .MuiTablePagination-select': {
                  display: 'none' // Hides "per page" dropdown
                },
                '& .MuiPagination-ul': {
                  justifyContent: 'flex-end' // Ensures pagination buttons align properly
                }
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </Suspense>
  );
};

export default PriceGroups;
