import React from 'react';
// import CartNotification from 'components/navbar/top/CartNotification';
// import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { Nav } from 'react-bootstrap';
// import NineDotMenu from './NineDotMenu';
// import ThemeControlDropdown from './ThemeControlDropdown';
// import OrganizationModal from './Organization';
// import { SiAwsorganizations } from 'react-icons/si';

const TopNavRightSideNavItem = () => {
  // const [showLocationModal, setShowLocationModal] = useState(false);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* <ThemeControlDropdown /> */}
      {/* <CartNotification /> */}
      {/* <NotificationDropdown /> */}
      {/* <NineDotMenu /> */}
      {/* <Nav.Item as="li">
        <Nav.Link onClick={() => setShowLocationModal(true)}>
          <SiAwsorganizations size={26} />
        </Nav.Link>
      </Nav.Item>
      <OrganizationModal
        show={showLocationModal}
        onHide={() => setShowLocationModal(false)}
      /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
