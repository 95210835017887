import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, Image } from 'react-bootstrap';
import Slider from 'react-slick';

const ProductImages = ({ data: { media } }) => {
  const sliderRef = React.useRef(null);

  const uniqueMedia = useMemo(() => {
    const uniqueUrls = new Set();
    return media?.filter(image => {
      if (!uniqueUrls.has(image.url)) {
        uniqueUrls.add(image.url);
        return true;
      }
      return false;
    });
  }, [media]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 500,
    cssEase: 'linear'
  };

  const handleThumbnailClick = index => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <>
      <Container fluid className="px-5 bg-white">
        <Row className="mb-3">
          <Col>
            <div className="">
              <Slider ref={sliderRef} {...settings}>
                {uniqueMedia.map((image, index) => (
                  <div key={index}>
                    <Image fluid src={image['url']} alt="" key={index} />
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
        <Row className="g-1 py-2">
          {uniqueMedia.map((image, index) => (
            <Col
              className="d-flex justify-content-space-between align-items-center"
              key={index}
            >
              <div
                role="button"
                className={'overflow-hidden border border-400'}
                onClick={() => handleThumbnailClick(index)}
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={image.url}
                  alt={`Product thumbnail ${index + 1}`}
                  className="img-fluid"
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

ProductImages.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};

export default ProductImages;
