import React, { useState } from 'react';
import { Card, Col, Row, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './Products.css';
import Overview from './tabsContent/Overview';
import Variants from './tabsContent/Variants';
import ProductImages from './tabsContent/ProductImages';
import Activity from './tabsContent/Activity';

const Tab2Content = () => <div>Orders Content</div>;
const Tab4Content = () => <div>Documents Content</div>;

const ProductInfo = ({ data }) => {
  const [activeTab, setActiveTab] = useState('Overview');
  const renderTabContent = () => {
    switch (activeTab) {
      case 'Overview':
        return <Overview data={data} />;
      case 'Variants':
        return <Variants data={data} />;
      case 'Orders':
        return <Tab2Content />;
      case 'Images':
        return <ProductImages data={data} />;
      case 'Documents':
        return <Tab4Content />;
      case 'Activity':
        return <Activity data={data} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Row className="g-3 align-items-center">
        <Col xs={12}>
          <Card className="mb-3">
            <Card.Header as="h6">
              <Nav
                variant="tabs"
                activeKey={activeTab}
                onSelect={tab => setActiveTab(tab)}
                className="custom-tabs pb-3"
              >
                <Nav.Item>
                  <Nav.Link eventKey="Overview">Overview</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Variants">Variants</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Orders">Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Images">Images</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Documents">Documents</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Activity">Activity</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>{renderTabContent(activeTab)}</Col>
      </Row>
    </>
  );
};

ProductInfo.propTypes = {
  data: PropTypes.object.isRequired
};

export default ProductInfo;
