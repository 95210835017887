import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getCountryCode } from 'helpers/utils';

const CountryInputWithFlag = ({ country_of_origin, isReadOnly }) => {
  if (!country_of_origin) return <></>;
  let flagUrl = '';
  if (country_of_origin.length === 2) {
    flagUrl = `https://flagcdn.com/w40/${country_of_origin.toLowerCase()}.png`;
  } else {
    flagUrl = `https://flagcdn.com/w40/${getCountryCode(
      country_of_origin
    ).toLowerCase()}.png`;
  }

  return (
    <Form.Group className="mb-3" controlId="country-of-origin">
      <Form.Label>Country of Origin</Form.Label>
      <div className="position-relative">
        <Form.Control
          size="sm"
          type="text"
          name="country_of_origin"
          value={country_of_origin}
          readOnly={isReadOnly}
          style={{
            paddingLeft: '40px',
            backgroundImage: `url(${flagUrl})`,
            backgroundSize: '20px 15px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '10px center',
            textIndent: '10px'
          }}
        />
        {/* <span
          className="position-absolute"
          style={{
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            color: 'black',
            pointerEvents: 'none'
          }}
        >
          {country_of_origin || 'India'}
        </span> */}
      </div>
    </Form.Group>
  );
};

CountryInputWithFlag.propTypes = {
  country_of_origin: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired
};

export default CountryInputWithFlag;
