import React, { useState, useEffect, Suspense } from 'react';
import { Card, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport
} from '@mui/x-data-grid';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, formatNumberWithCommas } from 'helpers/utils';
import SubtleBadge from 'components/common/SubtleBadge';
import LoadingSpinner from 'components/common/LoadingSpinner';

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}orders`;

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const Orders = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortModel, setSortModel] = useState([{ field: 'date', sort: 'desc' }]);

  const navigate = useNavigate();

  const CustomExportButton = () => {
    const handleExport = async () => {
      let allData;
      try {
        const t = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}`, {
          headers: {
            Authorization: `Bearer ${t}`
          },
          params: {
            page: 0,
            per_page: 99999
          }
        });

        allData = (response.data?.data || []).map(row => {
          const account = row.account ? row.account.legal_name : ' ';
          const channel = row.channel ? row.channel.name : ' ';

          return {
            number: row.number,
            date: new Date(row.date).toLocaleString(),
            type: row.type,
            account: account,
            channel: channel,
            total_quantity: row.total_quantity,
            total_amount: row.total_amount,
            shipping_date: new Date(row.shipping_date).toLocaleString(),
            stage: row.stage
          };
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      const csv = Papa.unparse(allData);
      const blob = new Blob([csv], { type: 'text/csv' });
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const fileName = `orders_data_${timestamp}.csv`;
      saveAs(blob, fileName);
    };

    return (
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="external-link-alt"
        transform="shrink-3"
        onClick={handleExport}
      >
        <span className="d-none d-sm-inline-block ms-1">Export All</span>
      </IconButton>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px'
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <CustomExportButton />
      </GridToolbarContainer>
    );
  };

  const fetchData = async (signal, model = []) => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}`, {
        signal,
        headers: {
          Authorization: `Bearer ${t}`
        },
        params: {
          page: page + 1,
          per_page: pageSize,
          sort: model[0]?.field || sortModel[0]?.field || 'date',
          sort_dir: model[0]?.sort || sortModel[0]?.sort || 'desc',
          ...(searchQuery && {
            search: searchQuery,
            search_fields:
              'number,type,account.legal_name,channel.name,total_quantity,total_amount,stage'
          })
        }
      });

      if (!signal.aborted) {
        const transformedData = (response.data?.data || []).map(row => {
          const account = row.account ? row.account.legal_name : ' ';
          const channel = row.channel ? row.channel.name : ' ';
          return {
            ...row,
            accountName: account,
            channelName: channel
          };
        });
        setData(transformedData);
        setRowCount(response.data?.meta?.total || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const debouncedFetch = debounce(() => fetchData(signal, sortModel), 300);
    debouncedFetch();

    return () => {
      controller.abort();
    };
  }, [page, pageSize, searchQuery, sortModel]);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleSortChange = model => {
    setSortModel(model.length ? model : []);
  };

  const handleOrderClick = id => {
    navigate(`/sales/order-details/${id}`);
  };

  const columns = [
    {
      field: 'number',
      headerName: 'Order',
      flex: 1.5,
      sortable: true,
      renderCell: params => (
        <span
          style={{ cursor: 'pointer', color: '#007bff' }}
          onClick={e => {
            e.stopPropagation();
            handleOrderClick(params.row.id);
          }}
        >
          {params.value}
        </span>
      )
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1.5,
      sortable: true,
      renderCell: params => {
        const date = new Date(params.value);
        const dateOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        // const timeOptions = {
        //   hour: '2-digit',
        //   minute: '2-digit',
        //   hour12: true
        // };
        const formattedDate = new Intl.DateTimeFormat(
          'en-US',
          dateOptions
        ).format(date);
        // const formattedTime = new Intl.DateTimeFormat(
        //   'en-US',
        //   timeOptions
        // ).format(date);
        return <span>{formattedDate}</span>;
      }
    },
    { field: 'type', headerName: 'Type', flex: 0.75, sortable: true },
    {
      field: 'accountName',
      headerName: 'Account',
      flex: 2,
      sortable: true
    },
    {
      field: 'channelName',
      headerName: 'Channel',
      flex: 1,
      valueGetter: params => {
        const value = params || '';
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      }
    },
    {
      field: 'total_quantity',
      headerName: 'Items',
      sortable: true,
      flex: 0.5,
      valueGetter: params => +params || ''
    },
    {
      field: 'total_amount',
      sortable: true,
      headerName: 'Total',
      valueGetter: params => formatCurrency(params) || '',
      flex: 1,
      align: 'right'
    },
    {
      field: 'shipping_date',
      headerName: 'Shipping Date',
      sortable: true,
      flex: 2,
      renderCell: params => {
        const date = new Date(params.value);
        const dateOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        const formattedDate = new Intl.DateTimeFormat(
          'en-US',
          dateOptions
        ).format(date);
        return <span>{formattedDate}</span>;
      }
    },
    {
      field: 'stage',
      headerName: 'Stage',
      sortable: true,
      flex: 1,
      renderCell: params => {
        const stage = params.value || '';
        let bgStageColor = '';
        switch (stage.toLowerCase()) {
          case 'paid':
          case 'shipped':
          case 'completed':
          case 'delivered':
            bgStageColor = 'success';
            break;
          case 'cancelled':
            bgStageColor = 'danger';
            break;
          case 'booked':
            bgStageColor = 'info';
            break;
          default:
            bgStageColor = 'warning';
        }

        return (
          <SubtleBadge
            pill
            bg={`${bgStageColor}`}
            className="fs-11 ml-1 fw-normal"
          >
            {params.value}
          </SubtleBadge>
        );
      }
    }
  ];

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Card className="mb-3">
        <Card.Header className="mb-1 pb-1 d-flex justify-content-between">
          Orders
          <span className="text-muted ms-auto">
            {formatNumberWithCommas(rowCount)}{' '}
            <span className="small">records found</span>
          </span>
        </Card.Header>
        <Card.Body className="pt-1">
          <div className="mb-1 flex">
            <Form.Group>
              <Form.Control
                type="text"
                name="filter"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex', height: '75vh', width: '100%' }}>
            <DataGrid
              key={
                sortModel.length
                  ? `${sortModel[0]?.field}-${sortModel[0]?.sort}`
                  : 'default'
              }
              className="fs-10"
              rows={data}
              columns={columns}
              pagination
              density="compact"
              pageSizeOptions={[100]}
              pageSize={pageSize}
              paginationMode="server"
              rowCount={rowCount}
              checkboxSelection
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortChange}
              selectionModel={selectedRows}
              onSelectionModelChange={newSelection => {
                setSelectedRows(newSelection);
              }}
              onRowClick={(params, event) => {
                if (event.target.textContent === params.row.number) {
                  event.stopPropagation();
                }
              }}
              loading={loading}
              onPaginationModelChange={pagination => {
                setPage(pagination.page);
                setPageSize(pagination.pageSize);
              }}
              slots={{
                toolbar: CustomToolbar
              }}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#2c7be5',
                  fontWeight: 'bold'
                },
                '& .MuiDataGrid-footerContainer': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                },
                '& .MuiPagination-root': {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                },
                '& .MuiDataGrid-toolbarContainer': {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '2px',
                  color: 'black',
                  minHeight: '32px'
                },
                '& .MuiButtonBase-root': {
                  color: 'black',
                  minHeight: '24px'
                },
                '& .MuiSvgIcon-root': {
                  color: 'black',
                  fontSize: '1rem'
                },
                '& .MuiTablePagination-selectLabel': {
                  display: 'none',
                  paddingTop: '12px'
                },
                '& .MuiTablePagination-displayedRows': {
                  display: 'none', // Hides record count text
                  paddingTop: '12px'
                },
                '& .MuiTablePagination-select': {
                  display: 'none' // Hides "per page" dropdown
                },
                '& .MuiPagination-ul': {
                  justifyContent: 'flex-end' // Ensures pagination buttons align properly
                }
              }}
            />
          </div>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </Suspense>
  );
};

export default Orders;
