import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
const API_URL = `${process.env.REACT_APP_API_ENDPOINT}users`;

const GetUserInfoFromAPI = ({ userId, whatToDisplay }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const fetchUser = async () => {
      try {
        const t = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/${userId}`, {
          headers: {
            Authorization: `Bearer ${t}`
          }
        });
        setUser(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error: {error.message}</>;
  }

  if (whatToDisplay === 'fullname') {
    return (
      <>
        {user?.first_name && user?.last_name
          ? `${user.first_name} ${user.last_name}`
          : 'Unknown User'}
      </>
    );
  }

  return <>{user?.email}</>;
};

GetUserInfoFromAPI.propTypes = {
  userId: PropTypes.string.isRequired,
  whatToDisplay: PropTypes.string
};

export default GetUserInfoFromAPI;
