import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'assets/img/icons/spot-illustrations/45.png';
import paths from 'routes/paths';

const loginPaths = {
  simple: paths.simpleLogin,
  split: paths.splitLogin,
  card: paths.cardLogin
};

const LogoutContent = ({ layout = 'split', titleTag: TitleTag = 'h4' }) => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const duration = 5000; // 5 seconds
    const interval = 50; // Update every 50ms
    const step = (interval / duration) * 100;

    const timer = setInterval(() => {
      setProgress(prevProgress => {
        const nextProgress = prevProgress + step;
        if (nextProgress >= 100) {
          clearInterval(timer);
          navigate(loginPaths[layout]);
          return 100;
        }
        return nextProgress;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [layout, navigate]);

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={logoutImg}
        alt="shield"
        width={100}
      />
      <TitleTag>See you again!</TitleTag>
      <p>
        You are <br className="d-none d-sm-block" />
        now successfully signed out.
      </p>
      <div className="mt-2 mb-2">
        <ProgressBar
          now={progress}
          animated
          style={{
            backgroundColor: '#00527f'
          }}
        />
        <small className="text-muted">
          Redirecting in {Math.ceil((100 - progress) / 20)} seconds...
        </small>
      </div>
      <Button
        as={Link}
        size="sm"
        className="mt-3"
        to={loginPaths[layout]}
        style={{
          backgroundColor: '#00527f',
          color: 'white',
          borderColor: '#00527f'
        }}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to Login Now
      </Button>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

export default LogoutContent;
