import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SubtleBadge from 'components/common/SubtleBadge';

const ProductDetailsHeader = ({ data }) => {
  const { category, active, name, sku, media = [] } = data || {};
  const thumbnail = media[0]?.url;

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="g-2">
          <Col>
            <p className="text-capitalize pe-3 d-inline-block">
              {category?.full_name.replaceAll('/', ' >> ')}
            </p>
            <p className="text-capitalize mb-0 d-inline-block">
              {active ? (
                <SubtleBadge pill bg="success" className="fs-10 ml-1 fw-normal">
                  Active
                </SubtleBadge>
              ) : (
                <SubtleBadge pill bg="danger" className="fs-10 ml-1 fw-normal">
                  Inactive
                </SubtleBadge>
              )}
            </p>
            <h1 className="mb-0 mt-0 pt-0 pb-0 fs-7">
              <div
                className="text-wrap"
                style={{ maxWidth: '100%', wordBreak: 'break-word' }}
              >
                {name}
              </div>
            </h1>
            <p className="mb-0 mt-2 pt-0 pb-0 fs-8">{sku}</p>
          </Col>
          {thumbnail && (
            <Col xs="auto">
              <div className="d-flex justify-content-end text-end pe-3">
                <img
                  src={thumbnail}
                  alt={name}
                  style={{
                    maxWidth: '125px',
                    maxHeight: '125px'
                  }}
                ></img>
              </div>
            </Col>
          )}
        </Row>
      </Card.Header>
    </Card>
  );
};

ProductDetailsHeader.propTypes = {
  data: PropTypes.object.isRequired
};

export default ProductDetailsHeader;
