// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
// import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';

const getInitials = () => {
  try {
    const userLCData = localStorage.getItem('user');
    if (!userLCData) return '';

    const userData = JSON.parse(userLCData);
    if (!userData) return '';

    const { first_name = '', last_name = '' } = userData;
    return `${first_name.charAt(0)}${last_name.charAt(0)}`;
  } catch (error) {
    console.error('Error parsing user data:', error);
    return '';
  }
};

const ProfileDropdown = () => {
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar isExact name={getInitials()} mediaClass="bg-white text-dark" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item>
          <Dropdown.Item as={Link} to={paths.userProfile}>
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={paths.userSettings}>
            Settings
          </Dropdown.Item> */}
          <Dropdown.Item as={Link} to={paths.splitLogout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
