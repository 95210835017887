/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Collapse
} from 'react-bootstrap';
import { formatDateWithDifference } from 'helpers/utils';
import Flex from 'components/common/Flex';
import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_ENDPOINT}products`;
import CountUp from 'react-countup';
import GetUserInfoFromAPI from 'components/common/GetUserInfoFromAPI';

const ProductSideBar = ({ data }) => {
  const [openNote, setOpenNote] = useState(false);
  const [notes, setNotesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAllNotes, setShowAllNotes] = useState(false);

  const toggleNote = noteId => {
    setOpenNote(prev => ({
      ...prev,
      [noteId]: !prev[noteId]
    }));
  };

  const fetchNotes = async () => {
    setLoading(true);

    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/${data.id}/notes`, {
        headers: {
          Authorization: `Bearer ${t}`
        }
      });
      const resData = response.data?.data || [];
      console.log('45 resData', resData);
      setNotesData(resData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const displayedNotes = notes.slice(0, 3);
  const hasMoreNotes = notes.length > 3;

  const priceGroupDisplay = () => {
    return (
      <Card className="h-100 bg-info">
        <Card.Body>
          <Row className="flex-between-center">
            <Col className="d-md-flex d-lg-block flex-between-center">
              <h6 className="mb-md-0 text-white fs-9">Price</h6>
            </Col>
            {/* <SubtleBadge bg="success" pill>
                <FontAwesomeIcon icon="caret-up" /> 61.8%
              </SubtleBadge> */}
            <Col xs="auto">
              <h4 className="fs-6 fw-normal text-700">
                <CountUp
                  className="text-white"
                  start={0}
                  end={2300.19}
                  duration={0.5}
                  suffix={''}
                  prefix={'$'}
                  decimals={2}
                  decimal="."
                />
              </h4>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const displayCollections = () => {
    return (
      <div className="h-100 border bg-light text-dark">
        <div className="p-1 m-1">
          <div className="p-0">
            <div className="d-flex flex-wrap">
              {data?.collections?.map((collection, index) => (
                <div
                  key={index}
                  className="d-inline-flex align-items-center px-2 py-1 bg-300"
                  style={{
                    fontSize: '0.8rem',
                    transition: 'all 0.2s ease'
                  }}
                >
                  <span
                    className="text-muted me-2"
                    style={{
                      cursor: 'pointer',
                      fontSize: '0.8rem',
                      ':hover': {
                        color: '#dc3545'
                      }
                    }}
                    onClick={() => {
                      /* handle remove if needed */
                    }}
                  >
                    ×
                  </span>
                  <span> {collection.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displayKeywords = () => {
    return (
      <div
        className="h-100 border bg-light text-dark"
        style={{ minHeight: '45.1px' }}
      >
        <div className="p-1 m-1">
          <div className="p-0">
            <div className="d-flex flex-wrap">
              {data?.keywords?.map((keyword, index) => (
                <div
                  key={index}
                  className="d-inline-flex align-items-center px-2 py-1 bg-300"
                  style={{
                    fontSize: '0.8rem',
                    transition: 'all 0.2s ease'
                  }}
                >
                  <span
                    className="text-muted me-2"
                    style={{
                      cursor: 'pointer',
                      fontSize: '0.8rem',
                      ':hover': {
                        color: '#dc3545'
                      }
                    }}
                    onClick={() => {
                      /* handle remove if needed */
                    }}
                  >
                    ×
                  </span>
                  <span> {keyword.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="mb-3" style={{ minHeight: '450px' }}>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-2 text-uppercase border-bottom text-primary-emphasis">
              Notes
            </h6>
            {displayedNotes.length > 0 &&
              displayedNotes.map(el => {
                return (
                  <div key={el.id} className="mb-2">
                    <p
                      className="fw-bold mb-0 fs-10 cursor-pointer"
                      onClick={() => toggleNote(el.id)}
                      aria-expanded={openNote[el.id] || false}
                      aria-controls={`collapse-${el.id}`}
                    >
                      {el.title || 'No title'}
                    </p>
                    <Collapse in={openNote[el.id]}>
                      <p id={`collapse-${el.id}`} className="mb-0 fs-10">
                        {el.notes || 'No content available'}
                      </p>
                    </Collapse>
                    <p className="text-muted fst-italic mb-0 fs-10">
                      By {el?.updated_by?.first_name}{' '}
                      {el?.updated_by?.last_name} at{' '}
                      {formatDateWithDifference(el.updated_at).date}
                    </p>
                  </div>
                );
              })}
            {displayedNotes.length <= 0 && <>No Notes</>}
            {hasMoreNotes && (
              <Button
                variant="link"
                size="sm"
                className="ps-0 text-primary-emphasis"
                onClick={() => setShowAllNotes(true)}
              >
                View all {notes.length} notes
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-1 text-uppercase border-bottom text-primary-emphasis">
              Pricing
            </h6>
            {priceGroupDisplay()}
          </Flex>
        </Col>
      </Row>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-1 text-uppercase border-bottom text-primary-emphasis">
              Collections
            </h6>
            {displayCollections()}
          </Flex>
        </Col>
      </Row>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-1 text-uppercase border-bottom text-primary-emphasis">
              Keywords
            </h6>
            {displayKeywords()}
          </Flex>
        </Col>
      </Row>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-1 text-uppercase border-bottom text-primary-emphasis">
              Record Information
            </h6>
            <div className="d-flex justify-content-between w-100">
              <span className="fs-10">
                Created by{' '}
                <Suspense fallback={<>Loading...</>}>
                  <GetUserInfoFromAPI
                    userId={data.created_by}
                    whatToDisplay="fullname"
                  />
                </Suspense>
              </span>
              <span className="text-muted fst-italic fs-10">
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id={`tooltip-${data.created_at}`}>
                      {formatDateWithDifference(data.created_at).date}
                    </Tooltip>
                  }
                >
                  <p className="mb-0 fs-10 cursor-pointer">
                    {formatDateWithDifference(data.created_at).timeAgo}
                  </p>
                </OverlayTrigger>
              </span>
            </div>
            <div className="d-flex justify-content-between w-100">
              <span className="fs-10">
                Updated by{' '}
                <Suspense fallback={<>Loading...</>}>
                  <GetUserInfoFromAPI
                    userId={data.updated_by}
                    whatToDisplay="fullname"
                  />
                </Suspense>
              </span>
              <span className="text-muted fst-italic fs-10">
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id={`tooltip-${data.updated_at}`}>
                      {formatDateWithDifference(data.updated_at).date}
                    </Tooltip>
                  }
                >
                  <p className="mb-0 fs-10 cursor-pointer">
                    {formatDateWithDifference(data.updated_at).timeAgo}
                  </p>
                </OverlayTrigger>
              </span>
            </div>
          </Flex>
        </Col>
      </Row>

      <Modal
        show={showAllNotes}
        onHide={() => setShowAllNotes(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>All Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {notes.map(el => (
            <div key={el.id} className="mb-3 border-bottom pb-2">
              <p className="text-muted fst-italic mb-0">
                <p className="fw-bold mb-0">{el.title}</p>
                <p className="mb-1 fs-9">{el.notes}</p>
                <p className="text-muted fst-italic mb-0 fs-10">
                  By {el?.user?.first_name} {el?.user?.last_name} at{' '}
                  {formatDateWithDifference(el.updated_at).date}
                </p>
              </p>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </Card>
  );
};

ProductSideBar.propTypes = {
  data: PropTypes.object.isRequired
};
export default ProductSideBar;
