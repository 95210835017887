import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Container } from 'react-bootstrap';
import Lottie from 'lottie-react';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Flex from 'components/common/Flex';
import axios from 'axios';
import MapComponent from './LocationsMarker';

const selectedLocationStyle = {
  backgroundColor: '#e9ecef',
  borderLeft: '4px solid #007bff',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)'
};

const Locations = ({ data }) => {
  const [locationData, setLocationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}accounts/${data.id}/locations`,
        {
          headers: {
            Authorization: `Bearer ${t}`
          }
        }
      );
      const sortedData = response.data?.data.sort((a, b) => {
        if (a.is_primary !== b.is_primary) {
          return b.is_primary - a.is_primary;
        }
        return a.name.localeCompare(b.name);
      });
      const mapedData = sortedData.map(el => ({
        ...el,
        lat: parseFloat(el.latitude),
        lng: parseFloat(el.longitude),
        latitude: parseFloat(el.latitude),
        longitude: parseFloat(el.longitude),
        coords: {
          lat: parseFloat(el.latitude),
          lng: parseFloat(el.longitude)
        }
      }));
      setLocationData(mapedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <Container fluid className="p-0 shadow-sm rounded h-100">
      <Row className="h-100">
        {/* LEFT SIDE: LOCATIONS LIST */}
        <Col md={4} className="overflow-auto" style={{ maxHeight: '80vh' }}>
          {locationData.length > 0 &&
            locationData.map((location, index) => (
              <div
                key={location.id || index}
                className={'bg-white p-3 mb-2 shadow-sm rounded cursor-pointer'}
                style={
                  selectedLocation === location ? selectedLocationStyle : {}
                }
                onClick={() => setSelectedLocation(location)}
              >
                <p className="text-dark mb-1 text-uppercase text-base fw-bolder">
                  {location.name}
                </p>
                <p className="mb-1 fs-10">
                  {location.street}
                  <br />
                  {location.city}, {location.state} {location.zip}
                  <br />
                  {location.country}
                </p>
                <Flex className="mt-2">
                  <Flex justifyContent="center" alignItems="center">
                    {!!location.is_primary && (
                      <>
                        <Lottie
                          animationData={checkPrimaryLight}
                          loop={false}
                          style={{ height: '28px', width: '28px' }}
                        />
                        <p className="mb-0 flex-1 fs-10">Primary Location</p>
                      </>
                    )}
                  </Flex>
                  <Flex justifyContent="center" alignItems="center">
                    {!!location.is_billing && (
                      <>
                        <Lottie
                          animationData={checkPrimaryLight}
                          loop={false}
                          style={{ height: '28px', width: '28px' }}
                        />
                        <p className="mb-0 flex-1 fs-10">Billing</p>
                      </>
                    )}
                  </Flex>
                  <Flex justifyContent="center" alignItems="center">
                    {!!location.is_shipping && (
                      <>
                        <Lottie
                          animationData={checkPrimaryLight}
                          loop={false}
                          style={{ height: '28px', width: '28px' }}
                        />
                        <p className="mb-0 flex-1 fs-10">Shipping</p>
                      </>
                    )}
                  </Flex>
                </Flex>
              </div>
            ))}
        </Col>

        {/* RIGHT SIDE: GOOGLE MAP */}
        <Col md={8} className="h-100">
          <div className="position-sticky top-0" style={{ height: '80vh' }}>
            {locationData.length > 0 ? (
              <MapComponent
                locations={locationData}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            ) : (
              <p>Loading Map...</p>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

Locations.propTypes = {
  data: PropTypes.object.isRequired
};

export default Locations;
