/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Form, Col, Row, Card, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TinymceEditor from 'components/common/TinymceEditor';
import CountryInputWithFlag from 'components/common/CountryInputWithFlag';
const Overview = ({ data }) => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const {
    category,
    vendor = {},
    brand,
    sub_category,
    family,
    country_of_origin,
    description = ''
  } = data || {};

  const [productDescription, setProductDescription] = useState(description);

  return (
    <>
      <Card className="mb-3">
        <Card.Header
          as="h6"
          className="bg-body-tertiary text-primary-emphasis fw-semibold ls text-uppercase"
        >
          General Information
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  className="text-capitalize"
                  size="sm"
                  type="text"
                  name="category_name"
                  value={category?.name}
                  readOnly={isReadOnly}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="brand">
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  className="text-capitalize"
                  size="sm"
                  type="text"
                  name="brand"
                  value={brand}
                  readOnly={isReadOnly}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="subCategory">
                <Form.Label>Sub Category</Form.Label>
                <Form.Control
                  size="sm"
                  className="text-capitalize"
                  type="text"
                  name="sub_category"
                  value={sub_category}
                  readOnly={isReadOnly}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="country-of-origin">
                <CountryInputWithFlag
                  country_of_origin={country_of_origin}
                  isReadOnly={isReadOnly}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="family">
                <Form.Label>Family</Form.Label>
                <Form.Control
                  size="sm"
                  className="text-capitalize"
                  type="text"
                  name="family"
                  value={family}
                  readOnly={isReadOnly}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="vendor">
                <Form.Label>Vendor</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="vendor"
                  value={vendor?.name}
                  readOnly={isReadOnly}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header
          as="h6"
          className="bg-body-tertiary text-primary-emphasis fw-semibold ls text-uppercase"
        >
          Description
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <TinymceEditor
                value={productDescription}
                height="13.438rem"
                handleChange={newValue => setProductDescription(newValue)}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header
          as="h6"
          className="bg-body-tertiary text-primary-emphasis fw-semibold ls text-uppercase"
        >
          Components
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Table className="mb-2 bg-body-emphasis table-responsive">
                <thead className="table-dark">
                  <tr className="fs-10">
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">Item</th>
                    <th scope="col">Description</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Pieces</th>
                    <th scope="col">Unit Cost</th>
                    <th scope="col">Total Cost</th>
                  </tr>
                </thead>
                <tbody className="event-ticket-body"></tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

Overview.propTypes = {
  data: PropTypes.object.isRequired
};

export default Overview;
