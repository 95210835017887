import React, { useCallback, useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF
} from '@react-google-maps/api';
import PropTypes from 'prop-types';

const MapComponent = ({ locations, selectedLocation, setSelectedLocation }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });

  const [map, setMap] = React.useState(null);

  const onLoad = useCallback(
    map => {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach(location =>
        bounds.extend({ lat: location.lat, lng: location.lng })
      );
      map.fitBounds(bounds);
      setMap(map);
    },
    [locations]
  );

  useEffect(() => {
    if (map && selectedLocation) {
      map.panTo({ lat: selectedLocation.lat, lng: selectedLocation.lng });
      map.setZoom(14);
    }
  }, [selectedLocation, map]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {locations.map(location => (
        <MarkerF
          key={location.id}
          position={{ lat: location.lat, lng: location.lng }}
          onClick={() => setSelectedLocation(location)}
        />
      ))}
      {selectedLocation && (
        <InfoWindowF
          position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
          onCloseClick={() => setSelectedLocation(null)}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '15px',
              borderRadius: '8px',
              boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)',
              fontSize: '14px',
              maxWidth: '300px'
            }}
          >
            <h5
              style={{
                margin: '0 0 10px',
                color: '#333',
                fontSize: '16px',
                fontWeight: 'bold'
              }}
            >
              {selectedLocation.name}
            </h5>
            <p style={{ margin: '0', color: '#666', lineHeight: '1.5' }}>
              {selectedLocation.street}, {selectedLocation.city},{' '}
              {selectedLocation.state} {selectedLocation.zip}
              <br />
              {selectedLocation.country}
            </p>
          </div>
        </InfoWindowF>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

MapComponent.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      street: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string
    })
  ).isRequired,
  selectedLocation: PropTypes.object,
  setSelectedLocation: PropTypes.func.isRequired
};

export default MapComponent;
