// import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Button,
  // Col,
  Form
  // Row
} from 'react-bootstrap';
import {
  // Link,
  useNavigate
} from 'react-router-dom';
import { toast } from 'react-toastify';
// import paths from 'routes/paths';
import { useAuth } from '../../AuthContext';
import axios from 'axios';

// const forgotPasswordPaths = {
//   simple: paths.simpleForgotPassword
// };

const LoginForm = ({
  hasLabel = false
  // layout = 'split'
}) => {
  const auth = useAuth();
  const { login, token } = auth || {};

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (token && storedToken) {
      navigate('/');
    }
  }, [token, navigate]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}auth/login`,
        {
          email: formData.email,
          password: formData.password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      login(response.data.token);
      // console.log('Token:', response.data.token);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));

      if (response.data.token) {
        toast.success(`Logged in as ${formData.email}`, {
          theme: 'colored'
        });
        navigate('/');
      } else {
        const errorMessage = `Login failed(${response?.data?.errors?.email[0]}), please try again.`;
        toast.error(errorMessage, {
          theme: 'colored'
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Login failed, please try again.';
      toast.error(errorMessage, {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      {/* <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Link className="fs-10 mb-0" to={forgotPasswordPaths[layout]}>
            Forgot Password?
          </Link>
        </Col>
      </Row> */}

      <Form.Group>
        <Button
          type="submit"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
          style={{
            backgroundColor: '#00527f',
            color: 'white',
            borderColor: '#00527f'
          }}
        >
          {isLoading ? 'Logging in...' : 'Log in'}
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

export default LoginForm;
