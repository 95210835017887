/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Collapse
} from 'react-bootstrap';
import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs';
import { formatCurrency, formatDateWithDifference } from 'helpers/utils';
import Flex from 'components/common/Flex';
import axios from 'axios';
import GetUserInfoFromAPI from 'components/common/GetUserInfoFromAPI';
const API_URL = `${process.env.REACT_APP_API_ENDPOINT}accounts`;

const AccountSideBar = ({ data }) => {
  const [openNote, setOpenNote] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [notes, setNotesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAllNotes, setShowAllNotes] = useState(false);

  const toggleNote = noteId => {
    setOpenNote(prev => ({
      ...prev,
      [noteId]: !prev[noteId] // Toggle the selected note
    }));
  };

  const fetchNotes = async () => {
    setLoading(true);

    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/${data.id}/notes`, {
        headers: {
          Authorization: `Bearer ${t}`
        }
      });
      const resData = response.data?.data || [];

      setNotesData(resData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const toggleBalanceVisibility = () => {
    setShowBalance(prevShowBalance => !prevShowBalance);
  };

  const displayedNotes = notes.slice(0, 3);
  const hasMoreNotes = notes.length > 3;

  if (loading) {
    return <div>Loading...</div>;
  }

  console.log(' 😍 data', data);

  return (
    <Card className="mb-3" style={{ minHeight: '450px' }}>
      <Row className="m-1">
        <Col sm={6} className="d-flex align-items-center mt-2">
          <span className="fs-9 text-body-emphasis">
            Balance:{' '}
            {showBalance ? `${formatCurrency(data.balance)}  ` : '******  '}
          </span>
          {showBalance ? (
            <BsEyeSlashFill
              className="mt-1 pl-2 cursor-pointer text-body-emphasis"
              onClick={toggleBalanceVisibility}
            />
          ) : (
            <BsEyeFill
              className="mt-1 pl-2 cursor-pointer text-body-emphasis"
              onClick={toggleBalanceVisibility}
            />
          )}
        </Col>
        <Col sm={6} className="d-flex justify-content-end">
          {/* <FaEdit className="mt-1 mr-2 fs-7 cursor-pointer text-body-emphasis" />
          <FaRegTrashAlt className="mt-1 fs-7 ml-2 cursor-pointer text-body-emphasis" /> */}
        </Col>
      </Row>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-2 text-uppercase border-bottom text-primary-emphasis">
              Notes
            </h6>
            {displayedNotes.map(el => {
              return (
                <div key={el.id} className="mb-2">
                  <p
                    className="fw-bold mb-0 fs-10 cursor-pointer"
                    onClick={() => toggleNote(el.id)}
                    aria-expanded={openNote[el.id] || false}
                    aria-controls={`collapse-${el.id}`}
                  >
                    {el.title || 'No title'}
                  </p>
                  <Collapse in={openNote[el.id]}>
                    <p id={`collapse-${el.id}`} className="mb-0 fs-10">
                      {el.notes || 'No content available'}
                    </p>
                  </Collapse>
                  <p className="text-muted fst-italic mb-0 fs-10">
                    By {el?.updated_by?.first_name} {el?.updated_by?.last_name}{' '}
                    at {formatDateWithDifference(el.updated_at).date}
                  </p>
                </div>
              );
            })}
            {displayedNotes.length <= 0 && <>No Notes</>}
            {hasMoreNotes && (
              <Button
                variant="link"
                size="sm"
                className="ps-0 text-primary-emphasis"
                onClick={() => setShowAllNotes(true)}
              >
                View all {notes.length} notes
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
      {/* <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-1 text-uppercase border-bottom text-primary-emphasis">
              Feeds
            </h6>
            <div>Feed data</div>
          </Flex>
        </Col>
      </Row> */}
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-1 text-uppercase border-bottom text-primary-emphasis">
              Record Information
            </h6>
            <div className="d-flex justify-content-between w-100">
              <span className="fs-10">
                Created by{' '}
                <Suspense fallback={<>Loading...</>}>
                  <GetUserInfoFromAPI
                    userId={data?.created_by}
                    whatToDisplay="fullname"
                  />
                </Suspense>
              </span>
              <span className="text-muted fst-italic fs-10">
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id={`tooltip-${data.created_at}`}>
                      {formatDateWithDifference(data.created_at).date}
                    </Tooltip>
                  }
                >
                  <p className="mb-0 fs-10 cursor-pointer">
                    {formatDateWithDifference(data.created_at).timeAgo}
                  </p>
                </OverlayTrigger>
              </span>
            </div>
            <div className="d-flex justify-content-between w-100">
              <span className="fs-10">
                Updated by{' '}
                <Suspense fallback={<>Loading...</>}>
                  <GetUserInfoFromAPI
                    userId={data?.updated_by}
                    whatToDisplay="fullname"
                  />
                </Suspense>
              </span>
              <span className="text-muted fst-italic fs-10">
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id={`tooltip-${data.updated_at}`}>
                      {formatDateWithDifference(data.updated_at).date}
                    </Tooltip>
                  }
                >
                  <p className="mb-0 fs-10 cursor-pointer">
                    {formatDateWithDifference(data.updated_at).timeAgo}
                  </p>
                </OverlayTrigger>
              </span>
            </div>
          </Flex>
        </Col>
      </Row>

      {/* Modal for all notes */}
      <Modal
        show={showAllNotes}
        onHide={() => setShowAllNotes(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>All Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {notes.map(el => (
            <div key={el.id} className="mb-3 border-bottom pb-2">
              <p className="text-muted fst-italic mb-0">
                <p className="fw-bold mb-0">{el.title}</p>
                <p className="mb-1 fs-9">{el.notes}</p>
                <p className="text-muted fst-italic mb-0 fs-10">
                  By {el?.user?.first_name} {el?.user?.last_name} at{' '}
                  {formatDateWithDifference(el.updated_at)}
                </p>
              </p>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </Card>
  );
};

AccountSideBar.propTypes = {
  data: PropTypes.object.isRequired
};
export default AccountSideBar;
