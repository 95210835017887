import React from 'react';
import { useParams } from 'react-router-dom';

const OrderDetails = () => {
  const { orderId } = useParams();

  if (!orderId) {
    return <>No order ID!!!</>;
  }
  return <div>ORDER ID: {orderId}</div>;
};

export default OrderDetails;
